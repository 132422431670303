import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import config from "../../siteConfig"
import Icon from "./Icon"
import { GatsbyImage } from "gatsby-plugin-image"

const navigation = [
  { name: "Services", href: "/services" },
  { name: "About", href: "/about" },
  // { name: "Blog", href: "/blog" },
]

export default function Hero({
  heading,
  subheading,
  description,
  heroImage,
  imageAlt,
}) {
  const { phoneNumber } = config.siteMetadata

  return (
    <div className="relative bg-black overflow-hidden">
      <div className="">
        <div className="relative z-10 pb-16 bg-black md:pb-20 xl:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden xl:block absolute xl:right-0 2xl:-right-24 inset-y-0 h-full w-48 text-black transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-0">
              <nav
                className="relative flex items-center sm:h-10 xl:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 md:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <Link to="/">
                      <span className="sr-only">Hartford Taxi Service</span>
                      <Icon name="logo-icon" className="w-12" />
                    </Link>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="bg-black rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:ml-10 md:pr-4 md:space-x-8 md:flex-grow">
                  <div className="flex w-44">
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="font-medium text-gray-500 hover:text-white mr-6"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="flex flex-grow justify-end">
                    <a
                      href={`tel:${phoneNumber}`}
                      className="font-medium text-yellow-300 hover:text-yellow-500 flex items-center"
                    >
                      <div className="w-6 mr-2">
                        <Icon name="phone-icon" />
                      </div>
                      {phoneNumber}
                    </a>
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md p-px bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 lg:px-0 flex items-center justify-between">
                    <div>
                      <Icon name="logo-icon" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-black hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <a
                    href={`tel:${phoneNumber}`}
                    className="flex justify-center rounded-b-lg block w-full px-5 py-3 text-center font-medium text-yellow-300 bg-black hover:bg-gray-800"
                  >
                    <div className="w-6 mr-2">
                      <Icon name="phone-icon" />
                    </div>
                    {phoneNumber}
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-0 xl:mt-28">
            <div className="sm:text-center xl:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                <span className="block">{heading}</span>
                <span className="block text-yellow-300">{subheading}</span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0">
                {description}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center xl:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href={`tel:${phoneNumber}`}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent bg-yellow-400 text-base font-medium rounded-md text-white bg-black hover:bg-amber-700 md:py-4 md:px-10 text-2xl"
                  >
                    <div className="w-8 mr-3">
                      <Icon name="phone-icon" />
                    </div>
                    {phoneNumber}
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="hidden xl:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <GatsbyImage
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          image={heroImage}
          alt={imageAlt}
        />
      </div>
    </div>
  )
}
