import React from "react"

// const styles = {
//   color: "inherit",
//   fill: "currentColor",
//   width: "100%",
//   height: "inherit",
//   display: "block",
// }

const Icon = ({ name, className }) => {
  const Icon = require(`../svg/${name}.svg`)

  return <Icon className={`fill-current ${className}`} />
}

export default Icon
