var GOOGLE_ANALYTICS_ID = "G-836PXTR9LK"
var PHONE_NUMBER = "860-792-4447"

module.exports = {
  siteMetadata: {
    defaultTitle: `Hartford Taxi Service, 24 Hour Cab`,
    defaultDescription:
      `24-Hour reliable, affordable taxi cab services throughout the Hartford, CT area and to Bradley International Airport. Call us now at ` +
      PHONE_NUMBER +
      `!`,
    author: `@gatsbyjs`,
    siteUrl: `https://hartfordtaxiservice.com`,
    phoneNumber: PHONE_NUMBER,
  },
  googleAnalyticsOptions: {
    // trackingId: GOOGLE_ANALYTICS_ID,
    // head: false,
    // enableWebVitalsTracking: true,
    trackingIds: [GOOGLE_ANALYTICS_ID],
    gtagConfig: {
      anonymize_ip: true,
      cookie_expires: 0,
    },
  },
  webVitalsOptions: {
    trackingId: GOOGLE_ANALYTICS_ID,
    metrics: [`FID`, `TTFB`, `LCP`, `CLS`, `FCP`],
    eventCategory: "Performance",
    includeInDevelopment: false,
    debug: false,
  },
  manifestOptions: {
    name: "Hartford Taxi Service",
    short_name: `hartfordtaxi`,
    start_url: `/`,
    background_color: `#663399`,
    theme_color: `#663399`,
    display: `minimal-ui`,
    icon: `src/svg/logo-icon.svg`,
  },
}
